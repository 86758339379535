import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import Props from 'components/Props';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=Fieldset,Radio,Box wrap=function",
        "componentName": "Fieldset,Radio,Box",
        "wrap": "function"
      }}>{`() => {
  const values = ['video', 'killed', 'the', 'radio', 'star'];
  const [checkedValue, setCheckedValue] = React.useState(values[0]);
  const isChecked = value => value === checkedValue;

  return (
    <Fieldset label="The Buggles">
      {values.map(value => (
        <Box key={value} mb={5}>
          <Radio
            name="buggles"
            id={\`label-\${value}\`}
            value={value}
            label={value}
            checked={isChecked(value)}
            onChange={e => setCheckedValue(e.target.value)}
          />
        </Box>
      ))}
    </Fieldset>
  );
};
`}</code></pre>
    <h2 {...{
      "id": "props",
      "style": {
        "position": "relative"
      }
    }}>{`Props`}</h2>
    <Props identifier="Fieldset" mdxType="Props" />
    <h2 {...{
      "id": "action",
      "style": {
        "position": "relative"
      }
    }}>{`Action`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`Adds an action or extra information to your item, mostly used for links.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Fieldset
  label="Fieldset label"
  action={<LinkText href="#">LinkText</LinkText>}
>
  <Checkbox id="checkbox-action" label="Label" />
</Fieldset>
`}</code></pre>
    <h2 {...{
      "id": "disabled",
      "style": {
        "position": "relative"
      }
    }}>{`Disabled`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`Will disable all interaction of it's children.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Fieldset label="Fieldset label" disabled>
  <Checkbox mb={5} id="checkbox-disabled" label="Label" />
  <TextField id="textField-disabled" label="Label" />
</Fieldset>
`}</code></pre>
    <h2 {...{
      "id": "error",
      "style": {
        "position": "relative"
      }
    }}>{`Error`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`To convey an error to the user, you can set the `}<inlineCode parentName="p">{`error`}</inlineCode>{` prop to true.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Fieldset label="Fieldset label" message="Error only works on message" error>
  <Checkbox id="checkbox-error" label="Label" />
</Fieldset>
`}</code></pre>
    <h2 {...{
      "id": "message",
      "style": {
        "position": "relative"
      }
    }}>{`Message`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`Give additional information to the user.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Fieldset
  label="Fieldset label"
  message="Message can be set to provide additional information."
>
  <Checkbox id="checkbox-message" label="Label" />
</Fieldset>
`}</code></pre>
    <h2 {...{
      "id": "optionallabel",
      "style": {
        "position": "relative"
      }
    }}>{`OptionalLabel`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`Compared with TextField, the Fieldset is `}<strong parentName="p">{`not required`}</strong>{` by default. The
optionalLabel is additional feedback to the user, please provide it where
needed. This will not be passed to it's children. You still have to provide an
optionalLabel or required prop depending on the component.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Fieldset label="Fieldset label" optionalLabel="I'm optional">
  <Checkbox id="checkbox-optionalLabel" label="Label" />
</Fieldset>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      